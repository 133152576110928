/*Note: Auth Reducer ***/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authuserdetail: null,
  authrefreshToken: null,
  authjwToken: null,
  userName: null,
  userId: null,
  addressList: null,
  getloactions: null,
  isUrgenttView: false,
  isUrgentViewStop: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    OTP: (state, action) => {
      let { payload } = action;
      console.log("payload:", payload);
      state.authuserdetail = payload;
      state.authrefreshToken = payload?.refreshToken;
      state.authjwToken = payload?.jwToken;
      state.userName = payload?.userName;
      state.userId = payload?.userId;
    },

    GETCUSTOMERDETAILS: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.authuserdetail = payload;
      state.addressList = payload?.details;
    },

    GETLOACTIONS: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.getloactions = payload;
    },

    IS_URGENT_VIEW: (state, action) => {
      state.isUrgenttView = action.payload;
    },
    IS_URGENT_VIEW_STOP: (state, action) => {
      state.isUrgentViewStop = action.payload;
    },

    IS_URGENT_VIEW_CLEAR: (state, action) => {
      state.isUrgenttView = action.payload;
      state.isUrgentViewStop = action.payload;
    },
  },
});

export const {
  OTP,
  GETCUSTOMERDETAILS,
  GETLOACTIONS,
  IS_URGENT_VIEW,
  IS_URGENT_VIEW_STOP,
  IS_URGENT_VIEW_CLEAR
} = authSlice.actions;

export default authSlice.reducer;
