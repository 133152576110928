import React, { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CancelOrder, GetCustomerOrder } from '../../../../redux/store/action/unauth-action/order-action/order-action';
import dayjs from 'dayjs';
import { Button, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import Loader from '../../../../Loader/Loader';



function In_Process() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authuserdetail } = useSelector(({ authStates }) => authStates);
    const { orderList } = useSelector(({ orderStates }) => orderStates);

    const resHandler = (data) => {
        if (data) {
            // setIsloading(false)
        }
    }

    useEffect(() => {
        dispatch(GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler))
    }, [dispatch, authuserdetail?.customer?.customerId]);

    const data = useMemo(() => orderList?.orders ? orderList?.orders.filter(row => row.status !== 'Delivered' && row?.status !== 'Deactivated') : [], [orderList?.orders]);
    console.log("data: ", data);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'img',
                header: '',
                Cell: ({ row }) => (
                    <div
                        className="order_list_card_inner_div"
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '100px',
                            boxShadow: '0px 0px 5px lightgray',
                            padding: 5,
                        }}
                    >
                        <img src={cylinder_img} alt="" />
                    </div>
                ),
            },
            {
                accessorKey: 'id',
                header: 'Order Id',
            },
            {
                accessorKey: 'requiredDate',
                header: 'Date',
                Cell: ({ row }) => (<p>{dayjs(row.original.currentScheduleStatus?.requiredDate).format('DD-MM-YYYY')}</p>),
            },
            {
                accessorKey: 'status',
                header: 'Status',
                filterFn: (rows) => rows.filter(row => row.original.status !== 'Delivered'),
            },
            {
                accessorKey: 'quantity',
                header: 'Quantity',
                Cell: ({ row }) => (<p>{row.original.orderDetail?.qty}</p>),
            },
            {
                accessorKey: 'detail',
                header: 'Detail',
                Cell: ({ row }) => (
                    <div className='main_detail_button_holder'>
                        <button
                            style={{
                                width: "200px",
                                backgroundColor: '#C10100',
                                cursor: 'pointer',
                                border: '2px solid #C10100',
                                color: 'white',
                                borderRadius: '5px',
                                padding: 10
                            }}
                            onClick={() => openModal(row)}
                        >
                            Cancel Delivery
                        </button>
                    </div>
                ),
            },
        ],
        [cylinder_img]
    );

    const table = useMaterialReactTable({
        columns,
        data,

    });


    const [isLoading, setIsloading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [cancelorderreason, setcancelorderreason] = useState(null);
    const [orderid, setorderid] = useState(null);


    function openModal(row) {
        setorderid(row?.original?.orderDetail?.id);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    function CancelOrderfunction() {
        if (!cancelorderreason.trim()) {
            toast.error("Please enter a Cancel Order Reason!");
        } else {
            // setIsloading(true)
            let formData = {
                orderId: orderid,
                reason: cancelorderreason
            }

            dispatch(CancelOrder(formData, resHandler))
            setIsOpen(false);
        }
    }

    return (
        <>
            {isLoading ? (<Loader />) : (<></>)}

            {/* Cancel Delivery Modal Start Here */}
            <Modal
                open={modalIsOpen}
                onRequestClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='main_account_deletion_div'>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginTop: "60px",
                            marginBottom: "60px",
                        }}
                    >
                        Wht do You Want to Cancel?
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                            style={{ width: "90%" }}
                            id="outlined-multiline-static"
                            label="Cancel Delivery Reason"
                            multiline
                            minRows={4}
                            maxRows={8}
                            value={cancelorderreason}
                            onChange={(e) => setcancelorderreason(e.target.value)}
                        />
                    </div>

                    <Stack direction="row" justifyContent="center" spacing={7} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "green",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                            onClick={CancelOrderfunction}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </Stack>
                </div>
            </Modal>
            {/* Cancel Delivery Modal End Here */}

            <MaterialReactTable table={table} />
        </>
    )
}


export default In_Process
