import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import './FAQ.css'

function FAQ() {
    const [expanded, setExpanded] = useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <>
            <div className='main_faq_div'>
                <div className='inner_faq_div'>
                    <div className='main_faq_heading_div'>
                        <h2>FAQ’s for the Blue Customer Application:</h2>
                    </div>
                    <Accordion
                        expanded={expanded}
                        onChange={handleExpansion}
                        sx={{
                            '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                            '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>
                                What is the LPG Cylinder Mobile Application?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The LPG Cylinder Mobile Application allows customers to manage their LPG cylinder needs conveniently. Through the app, you can register, place orders, track deliveries, manage your account, and much more.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                On which platforms is the application available?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The application is available only on Android platforms. You can download it from the Google Play Store.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I register on the application?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To register, follow these steps:
                                <br />
                                <br />
                                1.	Download and install the app from the Google play store.
                                <br />
                                2.	Open the app and select "Register."
                                <br />
                                3.	Allow the app to access your current location.
                                <br />
                                4.	Enter your mobile number and other personal details and verify it using the OTP sent to your phone.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I log in to the application?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To log in, follow these steps:
                                <br />
                                <br />
                                1.	Open the app and select "Login."
                                <br />
                                2.	Enter your registered mobile number.
                                <br />
                                3.	Verify your identity using the OTP sent to your phone.
                                <br />
                                4.	Once verified, you will be logged in and directed to the dashboard.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I order a new LPG cylinder?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To order a new cylinder, follow these steps:
                                <br />
                                <br />
                                1.	Log in to the application.
                                <br />
                                2.	On the dashboard, tap the "Order Now" button.
                                <br />
                                3.	Insert the order details & payment option and press proceed to checkout.
                                <br />
                                4.	Your order will be placed, and you will receive a confirmation notification.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                Can I check the status of my order?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, you can check the status of your order in the "Your Orders" tab on the dashboard. This section provides details about all your current and past orders.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                Can I schedule a delivery for a later date?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                1.	When a customer places a new cylinder order, a dummy order is automatically created which is visible in the next order.
                                <br />
                                2.	Two days before the delivery date entered by the customer, they will receive a notification asking if they want to confirm their order for the same date.
                                <br />
                                3.	They can confirm the order, change the delivery date using the calendar button, or cancel the subscription to cancel the dummy order
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How can I track my LPG cylinder order?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To track your order, follow these steps:
                                <br />
                                <br />
                                1.	Log in to the application.
                                <br />
                                2.	Go to the "Your Orders" section from the dashboard.
                                <br />
                                3.	Select the order you want to track.
                                <br />
                                4.	You will see a live tracking feature showing the current status and location of your delivery.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                What if my order is delayed?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                If your order is delayed, the live tracking feature will provide updates on the estimated time of arrival. You can also contact customer support through the app for further assistance.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I cancel a subscription order?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To cancel a subscription order, go to the "Next Order" tab on the dashboard, select the subscription you wish to cancel, and follow the prompts to cancel it.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How can I check in-house cylinder availability at my premises?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The app provides an option to check the availability of cylinders at your doorstep. Navigate to the "In house cylinders" section on the dashboard and it will show the available cylinders along with the barcodes available at my premises.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I generate a request to return an empty cylinder or order a refill?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To generate a return request for an empty cylinder or to order a refill:
                                <br />
                                <br />
                                1.	Log in to the application.
                                <br />
                                2.	Go to the "unsubscribed Cylinders" section.
                                <br />
                                3.	Select the option to return an empty cylinder or order a refill.
                                <br />
                                4.	Follow the prompts to complete your request.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I update my profile information?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To update your profile information, follow these steps:
                                <br />
                                <br />
                                1.	Log in to the application.
                                <br />
                                2.	Go to the "My Account" section from the side menu.
                                <br />
                                3.	Update the necessary details such as your address, phone number, or email.
                                <br />
                                4.	Press Update Profile.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I delete my account?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To delete your account, go to the "Account Deletion" section from the side menu in the app, Mention the reason and follow the prompts. Please note that this action is irreversible.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How can I add other addresses?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To add other addresses, go to the "Manage address" section, select "Add Address," enter or select the current location along with the new address details, and save.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I top up my wallet using a Digicash card?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To top up your wallet using a Digicash card:
                                <br />
                                <br />
                                1.	Log in to the application.
                                <br />
                                2.	Go to the "Digicash Top-up" section.
                                <br />
                                3.	Enter the card details and the amount you wish to top up.
                                <br />
                                4.	Verify the transaction by confirming through the OTP generated to the registered mobile number
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                How do I contact customer support?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To contact customer support, follow these steps:
                                <br />
                                <br />
                                1.	Contact the Support team via mentioned Email, UAN or WhatsApp.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>
                                What should I do if I encounter technical issues with the app?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                If you encounter technical issues, try restarting the app or your device. If the problem persists, contact customer support through the "Support" section for further assistance.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </>
    );
}

export default FAQ;