/*Note: Auth Reducer ***/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    AllCylinderStatusList: null,
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        ALLCYLINDERSTATUSLIST: (state, action) => {
            const { payload } = action;
            console.log('payload:', payload);
            state.AllCylinderStatusList = payload
        },
    }
});

export const {
    ALLCYLINDERSTATUSLIST,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;