// Fixed code with corrected conditional rendering and useEffect dependencies

import React, { useEffect, useState } from "react";
import "./Header.css";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { SlHome, SlSettings } from "react-icons/sl";
import { CiUser } from "react-icons/ci";
import { MdLogout, MdManageAccounts } from "react-icons/md";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Link } from "react-router-dom";
import { LogoutUser } from "../../redux/store/action/auth-action/auth-action";
import { SelectAddress } from "../../redux/store/action/unauth-action/address-action/address-action";
import Account_Deletion from "../../Modal/Account-Deletion";
import { IS_URGENT_VIEW_CLEAR } from "../../redux/store/reducer/auth-reducer/auth-reducer";

const Header = () => {
  const dispatch = useDispatch();
  const { authuserdetail, addressList } = useSelector(
    ({ authStates }) => authStates
  );
  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const [isHeaderSettingOpen, setHeaderSettingOpen] = useState(true);
  const [open, setopen] = useState(false);

  const toggleSidebar = () => {
    setHeaderSettingOpen(!isHeaderSettingOpen);
  };

  const logout = () => {
    dispatch(LogoutUser());
    dispatch(IS_URGENT_VIEW_CLEAR(false));
  };

  useEffect(() => {
    if (!selectaddress && addressList && addressList.length > 0) {
      dispatch(SelectAddress(addressList[0]));
    }
  }, [selectaddress, addressList, dispatch]);

  const handleOpen = () => {
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  return (
    <>
      <Account_Deletion
        open={open}
        handleClose={handleClose}
        title="Modal title"
      >
        This is the modal content.
      </Account_Deletion>
      <div className="home-content">
        <div className="header_location_holder_div">
          <Link to="/manage-address">
            {selectaddress ? (
              <>
                <div className="header_location_heading_div">
                  {selectaddress.type}
                </div>
                <div className="header_location_div">
                  {selectaddress.address.length > 30
                    ? `${selectaddress.address.substring(0, 30)}...`
                    : selectaddress.address}
                </div>
              </>
            ) : (
              addressList &&
              addressList.length > 0 && (
                <>
                  <div className="header_location_heading_div">
                    {addressList[0].type}
                  </div>
                  <div className="header_location_div">
                    {addressList[0].address.length > 30
                      ? `${addressList[0].address.substring(0, 30)}...`
                      : addressList[0].address}
                  </div>
                </>
              )
            )}
          </Link>
        </div>
        <span className="text"></span>

        <div
          className={`main_header_user_and_icon_holder ${
            isHeaderSettingOpen ? "" : "main_header_user_and_icon_holder_active"
          }`}
          onClick={toggleSidebar}
        >
          <div className="header_user_icon">
            <FaUser />
          </div>
          <div className="header_user_name">
            {authuserdetail?.customer?.name?.length > 10
              ? `${authuserdetail.customer.name.substring(0, 10)}...`
              : authuserdetail?.customer?.name}
          </div>

          {isHeaderSettingOpen ? (
            <div className="header_user_name">
              <TiArrowSortedDown />
            </div>
          ) : (
            <div className="header_user_name">
              <TiArrowSortedUp />
            </div>
          )}
        </div>

        {!isHeaderSettingOpen && (
          <div className="main_header_setting_div">
            <div className="inner_header_setting_div">
              <Link to="/">
                <div>
                  <SlHome />
                </div>
                <div>Home</div>
              </Link>
            </div>
            <hr />
            <div className="inner_header_setting_div">
              <Link to="/my-profile">
                <div>
                  <CiUser />
                </div>
                <div>My Profile</div>
              </Link>
            </div>
            <hr />
            <div className="inner_header_setting_div">
              <Link to="/">
                <div>
                  <SlSettings />
                </div>
                <div>Setting</div>
              </Link>
            </div>
            <hr />
            <div className="inner_header_setting_div">
              <Link to="#" onClick={handleOpen}>
                <div>
                  <MdManageAccounts />
                </div>
                <div>Account Deletion</div>
              </Link>
            </div>
            <hr />
            <div
              className="inner_header_setting_div"
              style={{ color: "red" }}
              onClick={logout}
            >
              <div>
                <MdLogout />
              </div>
              <div>Logout</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
