import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { Chip } from "@mui/joy";
import mastercard from "../../../../../src/assets/mastercard.png";

export const ManageCards = () => {
  const navigate = useNavigate();


  const navigateToAddCard = () => {
    navigate('/add-card');
  };


  return (
    <>
      <div className='main_order_list_div'>

        <div className='main_order_list_tabs_section_div'>
          <div> <Link to='/My-Profile'>My Profile</Link></div>
          <div> <Link to='/order-list'>Order list</Link></div>
          <div> <Link to='/manage-address'>Manage Address</Link></div>
          <div className='order_list_tab_active'> <Link to='/Manage-Cards'>Manage Cards</Link></div>
          <div> <Link to='/wallet'>Wallet</Link></div>
        </div>

        <Card
          sx={{
            padding: 3,
            borderRadius: 4,
            boxShadow: 4,
          }}
        >
          <Stack rowGap={5}>

            <Card sx={{ padding: 2 }}>
              <div
                className="main_manage_card_div"
              // direction="row"
              //                 sx={{
              //                   justifyContent: "space-between",
              //                   alignItems: "center",
              //                 }}
              >
                <Stack
                  direction="row"
                  columnGap={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img width={57} height={48} src={mastercard} alt={mastercard} />
                  <Stack>
                    <Typography variant="h7" fontWeight="bold">
                      MasterCard
                    </Typography>
                    <Typography variant="h7" color="#9b9b9b" fontSize="10px">
                      *****2345
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Chip
                    size="lg"
                    variant="solid"
                    sx={{ color: "#fff", backgroundColor: "#64f249" }}
                  >
                    Remove
                  </Chip>
                  <Chip
                    size="lg"
                    variant="solid"
                    sx={{ color: "#fff", backgroundColor: "#ff0000" }}
                  >
                    Remove
                  </Chip>
                </Stack>
              </div>
            </Card>

            <Card sx={{ padding: 2 }}>
              <div
                className="main_manage_card_div"
              // direction="row"
              //                 sx={{
              //                   justifyContent: "space-between",
              //                   alignItems: "center",
              //                 }}
              >
                <Stack
                  direction="row"
                  columnGap={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img width={57} height={48} src={mastercard} alt={mastercard} />
                  <Stack>
                    <Typography variant="h7" fontWeight="bold">
                      MasterCard
                    </Typography>
                    <Typography variant="h7" color="#9b9b9b" fontSize="10px">
                      *****2345
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Chip
                    size="lg"
                    variant="solid"
                    sx={{ color: "#fff", backgroundColor: "#64f249" }}
                  >
                    Remove
                  </Chip>
                  <Chip
                    size="lg"
                    variant="solid"
                    sx={{ color: "#fff", backgroundColor: "#ff0000" }}
                  >
                    Remove
                  </Chip>
                </Stack>
              </div>
            </Card>

            <Card sx={{ padding: 2 }}>
              <div
                className="main_manage_card_div"
              // direction="row"
              //                 sx={{
              //                   justifyContent: "space-between",
              //                   alignItems: "center",
              //                 }}
              >
                <Stack
                  direction="row"
                  columnGap={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img width={57} height={48} src={mastercard} alt={mastercard} />
                  <Stack>
                    <Typography variant="h7" fontWeight="bold">
                      MasterCard
                    </Typography>
                    <Typography variant="h7" color="#9b9b9b" fontSize="10px">
                      *****2345
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" columnGap={1}>
                  <Chip
                    size="lg"
                    variant="solid"
                    sx={{ color: "#fff", backgroundColor: "#64f249" }}
                  >
                    Remove
                  </Chip>
                  <Chip
                    size="lg"
                    variant="solid"
                    sx={{ color: "#fff", backgroundColor: "#ff0000" }}
                  >
                    Remove
                  </Chip>
                </Stack>
              </div>
            </Card>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={navigateToAddCard}
                sx={{
                  width: "500px",
                  length: "100%",
                  borderRadius: "15px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Add Card
              </Button>
            </Box>
          </Stack>
        </Card>

      </div>
    </>
  );
};
