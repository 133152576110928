import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom/dist';
import { toast } from 'react-toastify';
import { FaMinus, FaPlus } from "react-icons/fa";
import { Box, Card, Divider } from '@mui/material';
import { ImLocation } from "react-icons/im";
import { FaArrowLeft } from "react-icons/fa";
import { GetCustomerOrder, GetCustomerScheduledOrder, ReturnSendRequest } from '../../../../redux/store/action/unauth-action/order-action/order-action';
import dayjs from 'dayjs';
import Loader from '../../../../Loader/Loader';
import { Button, IconButton, Modal, Rating, Stack, TextField, Typography } from '@mui/material';
import { GetListAllCylinderStatus } from '../../../../redux/store/action/unauth-action/dashboard-action/dashboard-action';
import { GetCustomerWallet } from '../../../../redux/store/action/unauth-action/wallet-action/wallet-action';
import CloseIcon from "@mui/icons-material/Close";
import checkedcylinder from "../../../../../src/assets/checkedcylinder.png";


const Unsubscribe_Cylinders = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);

    const { scheduledorderList } = useSelector(({ orderStates }) => orderStates);
    const { selectaddress } = useSelector(({ addressStates }) => addressStates);
    const { AllCylinderStatusList } = useSelector(({ dashboardStates }) => dashboardStates);
    const { authuserdetail } = useSelector(({ authStates }) => authStates);

    const [ReturnCylinder, setReturnCylinder] = useState(false)
    const [quantity, setQuantity] = useState(0);
    const [Open, setOpen] = useState(false);
    const [Verification, setVerification] = useState(false);
    const [verify, setverify] = useState(null);
    const [Verificationbtn, setVerificationbtn] = useState(false);
    const [Thankyou, setThankyou] = useState(false);


    const unsubscribeCylinderList = scheduledorderList.filter((item) => item.isScheduleActive == false)
    const totalUnsubscribedCylinders = unsubscribeCylinderList.filter((item) => item.totalCylindersInHand != 0).map((item) => item.totalCylindersInHand)

    const unsubscribed_cylinder_function = (text) => {
        let value = totalUnsubscribedCylinders.length < 0 ? 0 : totalUnsubscribedCylinders.reduce((a, b) => a + b, 0)
        if (value === 0) {
            toast.error("No Cylinder In Hand")
        } else {
            if (text.toLowerCase() === 'refill') {
                navigate('/order-now')
            } else {
                if (authuserdetail?.customer?.cnicIssueDate.toLowerCase() === 'na') {
                    setOpen(true)
                } else {
                    setReturnCylinder(true)
                }
            }
        }
    }

    const GoBack = () => {
        setQuantity(0);
        setReturnCylinder(false)
    }

    const plusHandlerAndMinus = (value) => {
        const newQuantity = quantity + value;

        if (totalUnsubscribedCylinders.length === 0) {
            toast.error("No Cylinder In Hand");
        } else if (newQuantity < 0) {
            toast.error("Quantity cannot be negative");
        } else if (newQuantity > totalUnsubscribedCylinders.length) {
            toast.info(`You can only Return ${totalUnsubscribedCylinders.length} cylinders.`);
        } else {
            setQuantity(newQuantity);
        }
    };

    const OtherresHandler = (data) => {
        if (data) { }
    }

    const resHandler = (data) => {
        if (data?.data?.statusCode === 200 || data?.data?.succeeded === true) {
            setIsloading(false);
            setVerification(false);
            setVerificationbtn(false);
            setverify(null)
            setThankyou(true)
            dispatch(GetListAllCylinderStatus(authuserdetail?.customer?.customerId, OtherresHandler));
            dispatch(GetCustomerOrder(authuserdetail?.customer?.customerId, OtherresHandler));
            dispatch(GetCustomerScheduledOrder(authuserdetail?.customer?.customerId, OtherresHandler));
            dispatch(GetCustomerWallet(authuserdetail?.customer?.customerId, OtherresHandler));
        }
    }

    const ReturnSendRequestFunction = () => {
        if (quantity === 0) {
            toast.error("Please Add Cylinder!");
        } else if (!verify) {
            toast.error("Select Checkbox!");
        } else {
            setIsloading(true)
            let formData = {
                customerId: authuserdetail?.customer?.customerId,
                itemId: 1,
                appType: "Web",
                invoiceType: "EmptyPickupOnly",
                customerDetailAddressId: selectaddress?.id,
                qty: quantity,
                requiredDate: dayjs().format('MM-DD-YYYY'),
                securityDeposit: 0
            }
            // console.log(formData);
            dispatch(ReturnSendRequest(formData, resHandler))
        }

    }

    function openModal(data) {
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
    }

    function GoProfileScreen() {
        navigate('/My-Profile')
    }

    const cancelhandleOpen = () => {
        if (quantity === 0) {
            toast.error("Please Add Cylinder!");
        } else {
            setVerification(true);
        }
    };

    const cancelhandleClose = () => {
        setVerification(false);
    };

    const handleVerificationChange = (e) => {
        if (e.target.checked) {
            setverify(e.target.checked)
            setVerificationbtn(e.target.checked);
        } else {
            setVerificationbtn(false);
            setverify(null)
        }
    };

    console.log("authuserdetail?.customer", authuserdetail?.customer);

    // authuserdetail?.customer.name
    // authuserdetail?.customer.cnic
    // authuserdetail?.customer.cnicIssueDate
    // authuserdetail?.customer.cnicExpiryDate


    const handleConfirmationModalClose = () => {
        setQuantity(0);
        GoBack();
        setThankyou(false)
        // navigate('/')
    };


    return (
        <>
            {isLoading ? (<Loader />) : (<></>)}

            <Modal
                open={Open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='main_account_deletion_div'>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginTop: "40px",
                            marginBottom: "30px",
                        }}
                    >
                        Please provide your CNIC issuance date to create a return request.
                    </Typography>
                    {/* <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton> */}

                    <Stack direction="row" justifyContent="center" spacing={7} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "300px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "red",
                                },
                            }}
                            onClick={GoProfileScreen}
                        >
                            Go to Profile Screen
                        </Button>
                    </Stack>
                </div>
            </Modal>

            <Modal
                open={Verification}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 600,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 10,
                    }}
                >
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginTop: "20px",
                            marginBottom: "30px",
                        }}
                    >
                        Verification
                    </Typography>
                    {/* <IconButton
                        aria-label="close"
                        onClick={cancelhandleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton> */}

                    <div className='main_table_popup_box_div'>
                        <table className="cnic-table">
                            <tr>
                                <th>Name</th>
                                <td>{authuserdetail?.customer.name}</td>
                            </tr>
                            <tr>
                                <th>CNIC no.</th>
                                <td>{authuserdetail?.customer.cnic}</td>
                            </tr>
                            <tr>
                                <th>CNIC issuance</th>
                                <td>{authuserdetail?.customer.cnicIssueDate}</td>
                            </tr>
                            <tr>
                                <th>CNIC expiry</th>
                                <td>{authuserdetail?.customer.cnicExpiryDate}</td>
                            </tr>
                        </table>
                        <div className="verification-text">
                            <input
                                type="checkbox"
                                id="verify"
                                name="verify"
                                checked={verify}
                                onChange={handleVerificationChange}
                            // onChange={(e) => setverify(e.target.checked)}
                            />
                            <label for="verify">
                                I certify that the CNIC details I have provided are accurate and legitimate, and I accept full responsibility for the information's authenticity.
                            </label>
                        </div>
                        <Typography
                            sx={{
                                color: "#1591c7",
                                fontSize: { xs: "1.8rem", sm: "1rem" },
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            Do you want to confirm this return request?
                        </Typography>
                    </div>

                    <Stack direction="row" justifyContent="center" spacing={5} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "green",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                            onClick={ReturnSendRequestFunction}
                            disabled={!Verificationbtn} // Disable button if Verificationbtn is false
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                            onClick={cancelhandleClose}
                        >
                            No
                        </Button>
                    </Stack>
                </Card>
            </Modal>

            {/* Thank you for your purchase Modal Start Here */}
            <Modal
                open={Thankyou}
                aria-labelledby="third-modal-title"
                aria-describedby="third-modal-description"
            >
                <div
                    className="main_modal_div"
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            className="blue_logo"
                            src={checkedcylinder}
                            alt="Order Placed"
                            style={{ width: "100px", height: "100px" }}
                        />
                    </Box>
                    <Typography
                        id="third-modal-title"
                        variant="h4"
                        sx={{ textAlign: "center", color: "#1591c7", fontWeight: "bold", fontSize: "23px" }}
                    >
                        Your return request for {quantity} cylinders has been received
                    </Typography>

                    <Typography sx={{ textAlign: "center", marginTop: "20px", fontSize: "20px" }}>
                        You will be notified defore pickup.
                    </Typography>

                    <Box mt={2} textAlign="center">
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "primary",
                                width: "70%",
                                height: "50px",
                                borderRadius: "20px",
                            }}
                            onClick={handleConfirmationModalClose}
                        >
                            Back To Home
                        </Button>
                    </Box>
                </div>
            </Modal>
            {/* Thank you for your purchase Modal End Here */}


            {ReturnCylinder ? (
                <div className='main_unsubscribe_cylinders_div'>
                    <div>
                        <div className='main_order_status_back_arrow_div'><FaArrowLeft onClick={GoBack} /></div>
                    </div>
                    <div className='main_heading_unsubscribe_cylinders_div'>Return Cylinder</div>
                    <div className='inner_unsubscribe_cylinders_div'>
                        <div className='heading_unsubscribe_cylinders_div'>Select the cylinders you want to return</div>
                        <div className='main_quantity_div'>
                            <div className='inner_quantity_heading_div'>Quantity</div>
                            <div className="main_add_quantity_div">
                                <div>
                                    <FaMinus onClick={() => plusHandlerAndMinus(-1)} />
                                </div>
                                <div>{quantity}</div>
                                <div>
                                    <FaPlus onClick={() => plusHandlerAndMinus(1)} />
                                </div>
                            </div>
                        </div>
                        <Divider variant="middle" flexItem />
                        <div className='main_show_selectaddress_div'>
                            <div className='inner_show_selectaddress_div1'>
                                <div style={{ color: "red" }}><ImLocation /></div>
                                <div>
                                    <div>Location for pickup</div>
                                    <div style={{ color: "#0A74B9" }}>{selectaddress?.address}</div>
                                </div>
                            </div>
                            <div className='inner_show_selectaddress_div2'><Link to='/manage-address'>Change the address</Link></div>
                        </div>
                        <Divider variant="middle" flexItem />
                        <div className='main_send_request_div'>
                            <button onClick={cancelhandleOpen}>Send Request</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='main_unsubscribe_cylinders_div'>
                    <div className='main_heading_unsubscribe_cylinders_div'>Unsubscribed Cylinder</div>
                    <div className='inner_unsubscribe_cylinders_div'>
                        <div className='heading_unsubscribe_cylinders_div'>You have {totalUnsubscribedCylinders.length < 0 ? 0 : totalUnsubscribedCylinders.reduce((a, b) => a + b, 0)} unsubscribed cylinder</div>
                        <div className='text_unsubscribe_cylinders_div'>Create another refill subscription for these cylinders</div>
                        <div className='button_holder_unsubscribe_cylinders_div'>
                            <button style={{ backgroundColor: "#2d4c9c" }} onClick={() => unsubscribed_cylinder_function("refill")}>Refill</button>
                            <div>OR</div>
                            <button style={{ backgroundColor: "#c10000" }} onClick={() => unsubscribed_cylinder_function("return")}>Return</button>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default Unsubscribe_Cylinders
