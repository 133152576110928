import React, { useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import order_del_car from "../../../../assets/order-del-car.png";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CancelOrder, GetOrderScheduleLogs } from '../../../../redux/store/action/unauth-action/order-action/order-action';
import dayjs from 'dayjs';
import { Box, Button, Card, Divider, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import Loader from '../../../../Loader/Loader';
import Inhousecylinder from "../../../../assets/cylinder.png";
import QRCode from 'react-qr-code';

const Your_Order = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [isLoading, setIsloading] = useState(false);
    const [showorderdetails, setshoworderdetails] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [orderid, setorderid] = useState(null);
    const [cancelorderreason, setcancelorderreason] = useState(null);
    const [OrderStatusOpen, setOrderStatusOpen] = useState(false);
    const [order_info_data, setorder_info_data] = useState(null);
    const [order_info_address, setorder_info_address] = useState(null);

    const { orderList, getorderlog } = useSelector(({ orderStates }) => orderStates);
    const { authuserdetail, addressList } = useSelector(({ authStates }) => authStates);

    const data = useMemo(() => {
        return orderList && orderList.orders
            ? orderList.orders.filter(row => row?.status !== 'Delivered' && row?.status !== 'Deactivated')
            : [];
    }, [orderList]);

    console.log("data: ", data);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                id: 'id',
                header: 'Order ID',
                // Cell: ({ row }) => (<p>{row.original?.id}</p>),
            },
            {
                accessorKey: 'upcomingOrderRequiredDate',
                header: 'Delivery Date',
            },
            {
                accessorKey: 'status',
                header: 'Status',
            },
            {
                accessorKey: 'orderDetail',
                id: 'orderDetail',
                header: 'Quantity',
                Cell: ({ row }) => (<p>{row.original?.orderDetail?.qty}</p>),
            },
            {
                accessorKey: 'total',
                id: 'total',
                header: 'Amount',
                Cell: ({ row }) => (<p>{(row.original?.orderDetail?.total - row?.original?.paidAmount).toFixed(2)}</p>),
            },
            {
                accessorKey: 'detail',
                header: 'Detail',
                Cell: ({ row }) => (
                    <div className='main_detail_button_holder'>
                        <button
                            style={{ backgroundColor: '#1563B1', cursor: 'pointer', border: '2px solid #1563B1', color: 'white', borderRadius: '5px', padding: 10 }}
                            onClick={() => OrderStatusopen(row)}
                        >
                            Order status
                        </button>
                        <button
                            style={{ backgroundColor: '#1563B1', cursor: 'pointer', border: '2px solid #1563B1', color: 'white', borderRadius: '5px', padding: 10 }}
                            onClick={() => orderdetail(row)}
                        >
                            Track your order
                        </button>
                        <button
                            style={{ backgroundColor: '#C10100', cursor: 'pointer', border: '2px solid #C10100', color: 'white', borderRadius: '5px', padding: 10 }}
                            onClick={() => openModal(row)}
                        >
                            Cancel delivery
                        </button>
                    </div>
                ),
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
    });

    const resHandler = (data) => {
        if (data) {
            setIsloading(false)
        }
    }

    const orderdetail = (row) => {
        dispatch(GetOrderScheduleLogs(row?.original?.orderDetail?.customerOrderId, resHandler))
        setshoworderdetails(true)
        // console.log(row.original);
        // console.log(row?.original?.orderDetail?.id);
    }

    const goback = () => {
        setshoworderdetails(false)
    }

    function openModal(row) {
        setorderid(row?.original?.orderDetail?.id);
        setIsOpen(true);
    }

    function closeModal() {
        setcancelorderreason(null);
        setIsOpen(false);
    }

    function CancelOrderfunction() {
        if (!cancelorderreason.trim()) {
            toast.error("Please enter a Cancel Order Reason!");
        } else {
            setIsloading(true)
            let formData = {
                orderId: orderid,
                reason: cancelorderreason
            }
            dispatch(CancelOrder(formData, resHandler))
            setIsOpen(false);
        }
    }

    function OrderStatusopen(row) {

        // console.log("addressList: ", addressList);
        console.log("row?.original: ", row?.original);

        dispatch(GetOrderScheduleLogs(row?.original?.orderDetail?.customerOrderId, resHandler))
        console.log(row.original)
        setorder_info_data(row?.original)
        // setorder_info_address(row?.original)
        setOrderStatusOpen(true);
    }

    function OrderStatusclose() {
        setOrderStatusOpen(false);
    }

    return (
        <>
            {isLoading ? (<Loader />) : (<></>)}

            {/* Cancel Order Modal Start Here */}
            <Modal
                open={modalIsOpen}
                onRequestClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className='main_account_deletion_div'>
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginTop: "60px",
                            marginBottom: "60px",
                        }}
                    >
                        Why do you want to Cancel
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className='CancelOrderOptionDiv'>
                        <div onClick={() => setcancelorderreason('Not available at home')}>Not available at home</div>
                        <div onClick={() => setcancelorderreason('Driver did not reach on specified time')}>Driver did not reach on specified time</div>
                    </div>

                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                            style={{ width: "90%" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="outlined-multiline-static"
                            label="Cancel Order Reason"
                            multiline
                            minRows={4}
                            maxRows={8}
                            value={cancelorderreason}
                            onChange={(e) => setcancelorderreason(e.target.value)}
                        />
                    </div>

                    <Stack direction="row" justifyContent="center" spacing={7} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "green",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                            onClick={CancelOrderfunction}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </Stack>
                </div>
            </Modal>
            {/* Cancel Order Modal End Here */}

            {/* Order Status Modal Start Here */}
            <Modal
                open={OrderStatusOpen}
                onRequestClose={OrderStatusclose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div
                    className="main_modal_div"
                    style={{ maxHeight: "90%", overflowY: "auto" }}
                >
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginBottom: "10px",
                        }}
                    >
                        Order Info
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={OrderStatusclose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className='main_order_info_top_card_div'>
                        <div className='inner_order_info_top_card_div'>
                            <div className='inner_order_info_top_card_img_div'>
                                <img src={Inhousecylinder} alt="" />
                            </div>
                            <div className='inner_order_info_top_card_text_div'>
                                <div className='Cylinder_teading'>Blue LPG Cylinder</div>
                                <div className='orderid_heading'>Order ID: {order_info_data?.orderDetail?.customerOrderId}</div>
                            </div>
                        </div>
                    </div>

                    <Box mt={1} mb={0}>
                        <Stack direction="row" justifyContent="space-between" p={1}>
                            <Typography sx={{ color: "#818181" }}>Driver Name</Typography>
                            <Typography sx={{ color: "#1591c7" }}>{order_info_data?.driverName}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={1}>
                            <Typography sx={{ color: "#818181" }}>Driver Number</Typography>
                            <Typography sx={{ color: "#1591c7" }}>{order_info_data?.driverPhoneNumber}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={1}>
                            <Typography sx={{ color: "#818181" }}>Address</Typography>
                            <Typography sx={{ color: "#1591c7" }}>{order_info_data?.address || "NA"}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={1}>
                            <Typography sx={{ color: "#818181" }}>Quantity</Typography>
                            <Typography sx={{ color: "#1591c7" }}>{order_info_data?.orderDetail?.qty}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={1}>
                            <Typography sx={{ color: "#818181" }}>Delivery Date</Typography>
                            <Typography sx={{ color: "#1591c7" }}>{dayjs(order_info_data?.currentScheduleStatus?.requiredDate).format('MM-DD-YYYY')}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={1}>
                            <Typography sx={{ color: "#818181" }}>Total</Typography>
                            <Typography sx={{ color: "#1591c7" }}>RS {(order_info_data?.orderDetail?.total - order_info_data?.paidAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                        </Stack >
                    </Box >
                    <Typography
                        sx={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "bold",
                            textAlign: "center",
                            paddingBottom: "15px"
                        }}
                    >
                        Tap on QR Code to Save
                    </Typography>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <QRCode
                            style={{ width: "130px", height: "130px" }}
                            value={`${order_info_data?.orderDetail?.customerOrderId}`}
                        />
                    </div>

                    <Typography
                        sx={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "bold",
                            textAlign: "start",
                        }}
                    >
                        Order Logs
                    </Typography>

                    <Box mt={1} mb={0}>
                        {getorderlog?.logs.map((logs, index) => (
                            <div key={index}>
                                <Stack direction="row" justifyContent="space-between" p={1}>
                                    <Typography sx={{ color: "#818181" }}>{dayjs(logs?.createdOn).format('DD-MM-YYYY hh:mm A')}</Typography>
                                    <Typography sx={{ color: "#1591c7" }}>
                                        {logs?.statusDescription}
                                    </Typography>
                                </Stack>
                                <Divider variant="middle" flexItem />
                            </div>
                        ))}
                    </Box>

                </div >
            </Modal >
            {/* Order Status Modal End Here */}

            {
                showorderdetails ?
                    (
                        <>
                            <div className='main_order_status_div'>

                                <div className='main_order_status_back_arrow_div'><FaArrowLeft onClick={goback} /></div>

                                <div className='inner_header_order_status_div'>
                                    <div className='inner_header_order_status_div1'>
                                        <div style={{ fontWeight: 600, fontSize: 18, color: "#1591C7" }}>Order status</div>
                                        <div style={{ fontWeight: 700, fontSize: 18 }}>Order Id : {getorderlog?.customerOrderId}</div>
                                        <div style={{ fontSize: 15 }}>Your order is expected to be deliver on {getorderlog?.requiredDate}</div>
                                    </div>
                                    <div className='inner_header_order_status_div2'>
                                        <img src={order_del_car} alt="" />
                                    </div>
                                </div>

                                <div className='inner_body_order_status_div'>
                                    {getorderlog?.logs.map((logs, index) => (
                                        <div key={index} className='inner_body_order_loaction_div'>
                                            <div className='inner_body_order_loaction_div1'>
                                                <div><FaCircleCheck style={{ color: "#03a07b", fontSize: "1.2rem" }} /></div>
                                                <div>
                                                    <div style={{ fontSize: "1.1rem" }}>{logs?.statusDescription}</div>
                                                </div>
                                            </div>
                                            <div className='inner_body_order_loaction_div1'>
                                                <div>{dayjs(logs?.createdOn).format('DD-MM-YYYY hh:mm A')}</div>
                                                <div><IoCalendarOutline style={{ color: "#1591C7" }} /></div>
                                            </div>
                                        </div>
                                    ))}

                                </div>

                            </div>
                        </>
                    ) : (
                        <>
                            <MaterialReactTable table={table} />
                        </>
                    )
            }

        </>
    )
}

export default Your_Order;