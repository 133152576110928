import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  TextField,
  Typography,
  Stack,
  Button,
  Link,
} from "@mui/material";
import Group116683x from "../../../assets/Group 11668@3x.png";
import Group116672x from "../../../assets/Group 11667@2x.png";
import Group116003x from "../../../assets/Group 11600@3x.png";
import appleplaystore from "../../../assets/AAsS@3x.png";
import en_badge_web_generic from "../../../assets/en_badge_web_generic@3x.png";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/store/action/auth-action/auth-action";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(null);

  const resHandler = (data) => {
    if (data?.data?.statusCode === 200) {
      setIsloading(false)
      localStorage.setItem("phoneNumber", phoneNumber);
      navigate("/otp");
    } else {
      setIsloading(false)
    }
  };

  const login = () => {
    if (!phoneNumber) {
      toast.error("Please enter a Phone Number!");
    } else {
      setIsloading(true)

      let formData = {
        phoneNumber: phoneNumber,
        version: "Web"
      };

      dispatch(loginUser(formData, resHandler));
    }
  };

  return (
    <>
      {isLoading ? (<Loader />) : (<></>)}

      <Box
        sx={{
          backgroundImage: `url('${Group116683x}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100dvh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: 2, sm: 4 },
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={6}
            md={6}
            lg={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 4, md: 0 },
            }}
          >
            <img
              style={{ maxHeight: { xs: 300, md: 624 }, width: "100%", objectFit: "contain" }}
              loading="lazy"
              src={Group116672x}
              alt="Group11667"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ maxWidth: 500, width: "100%", p: 2 }}>
              <Card sx={{ padding: 3, borderRadius: 4 }}>
                <Stack sx={{ p: { xs: 0, sm: 3 }, rowGap: { xs: 2, sm: 4 } }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="blue_logo_img"
                      loading="lazy"
                      src={Group116003x}
                      alt="Group11600"
                    />
                  </Box>
                  <Typography variant="h4" fontWeight="bold" textAlign="center" sx={{ fontSize: { xs: 25, sm: 30 } }}>
                    Login
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    label="Registered Contact Number"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value.slice(0, 11))}
                    required
                  />
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{ borderRadius: 5, fontSize: { xs: 15, sm: 20 }, fontWeight: "bold" }}
                    onClick={login}
                  >
                    Generate OTP
                  </Button>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    columnGap={1}
                  >
                    <Typography sx={{ fontSize: { xs: 13, sm: 15 } }}>
                      Don't have an account?
                      <Link
                        href="/SignUp"
                        component="a"
                        sx={{ textDecoration: "none" }}
                      >
                        Create Account
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          height: "auto",
                        }}
                        loading="lazy"
                        src={en_badge_web_generic}
                        alt="Google Play Store"
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "100%",
                          maxWidth: "130px",
                          height: "auto",
                        }}
                        loading="lazy"
                        src={appleplaystore}
                        alt="Apple Play Store"
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Stack
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            // p: 1,
            // backgroundImage: "linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%)",
          }}
        >
          {/* <Box>
            <Typography variant="body2" align="center" color="#fff">
              Powered by PSO
            </Typography>
          </Box> */}
          <div className="main_footer_div">
            <div className="inner_footer_div1">
              <p>
                <a href="/Privacy-Policy">
                  Privacy Policy
                </a>
              </p>
              <hr />
              <p>
                <a href="/Terms-and-Condition">
                  Terms and Condition
                </a>
              </p>
              <hr />
              <p>Account Deactivation</p>
            </div>
            <div className="inner_footer_div2">
              <p>© Copyright by PSO</p>
            </div>
          </div>
        </Stack>
      </Box>
    </>
  );
}

export default SignIn;
