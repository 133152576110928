// import React from "react";
// import urgentImage from "../assets/urgent_dc.png";
// import CloseIcon from "@mui/icons-material/Close";
// const UrgentDCModal = ({ handleStopIsUrgentView }) => {
//   return (
//     <div style={styles.urgentDC_PopUp}>
//       <div style={styles.urgentDC_PopUp_Container}>
//         <img
//           src={urgentImage} // Update path
//           alt="Urgent"
//           style={{ width: 650, height: 800 }}
//         />
//         <button onClick={handleStopIsUrgentView} style={styles.closeBtn}>
//           <CloseIcon style={{ fontSize: 24, color: "black" }} />
//         </button>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   urgentDC_PopUp: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0, 0, 0, 0.6)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1000,
//   },
//   urgentDC_PopUp_Container: {
//     position: "relative",
//   },
//   closeBtn: {
//     position: "absolute",
//     top: 10,
//     right: 35,
//     backgroundColor: "white",
//     borderRadius: "50%",
//     border: "none",
//     cursor: "pointer",
//     padding: "5px",
//   },
// };

// export default UrgentDCModal;



import React from "react";
import urgentImage from "../assets/urgent_dc.png";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles/UrgentDCModal.module.css"; // Import the CSS module

const UrgentDCModal = ({ handleStopIsUrgentView }) => {
  return (
    <div className={styles.urgentDC_PopUp}>
      <div className={styles.urgentDC_PopUp_Container}>
        <img
          src={urgentImage} // Update path
          alt="Urgent"
          style={{ width: "100%", height: "auto" }} // Adjusted for responsiveness
        />
        <button onClick={handleStopIsUrgentView} className={styles.closeBtn}>
          <CloseIcon style={{ fontSize: 24, color: "black" }} />
        </button>
      </div>
    </div>
  );
};

export default UrgentDCModal;
