import React from 'react'
import blue_logo from "../../../assets/Group 11600@3x.png";


function Account_Deactivation() {

    return (
        <>
            <div className="main_privacy_policy_section_div">
                <div className="inner_body_privacy_policy_section_div">
                    <div className="main_header_section_div">
                        <div>
                            <img src={blue_logo} id="" />
                        </div>
                        <div className="main_login_div">
                            {/* LOGIN IN
                            <IoIosLogOut /> */}
                        </div>
                    </div>
                    <div className="main_banner_section_div">
                        Account Deactivation
                    </div>

                    {/* <div className="date_section_div">Account Deletion Policy</div> */}

                    <div className="main_text_privacy_policy_section_div">
                        <div className="left_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                Important Notes:
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <p>Account deletion is a permanent action. Once your account is deleted, all data associated with it will be permanently removed and cannot be recovered.</p>
                                <p>Please ensure you have saved any important information before initiating the deletion process.</p>
                                <p>If you have any concerns or questions regarding the deletion of your account, please contact our support team prior to proceeding.</p>
                                <p>By following these steps, you ensure that the account deletion process is completed smoothly and with full understanding of its consequences.</p>
                            </div>
                        </div>
                        <div className="right_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                Account Deletion Policy
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <div>
                                    <ul>
                                        <li><b>Accessing the Account Deletion Tab:</b></li>
                                        <p>To initiate the account deletion process, navigate to the Side Bar and select the "Account Deletion" tab.</p>
                                        <li><b>Confirmation of Account Deletion:</b></li>
                                        <p>Upon selecting the "Account Deletion" tab, a pop-up screen will appear with the message: "Are you sure you want to delete this account?"</p>
                                        <li><b>Providing a Reason for Deletion:</b></li>
                                        <p>Before proceeding, you will be prompted to type a reason for the account deletion. This step is required to help us understand your reasons for leaving and to improve our services.</p>
                                        <li><b>Acknowledgment of Irreversibility:</b></li>
                                        <p>You must acknowledge the irreversible nature of this action by clicking on the checkbox next to the statement: "I understand that deleting my account is irreversible, and all my data will be removed." This acknowledgment ensures that you are aware that once the account is deleted, it cannot be restored, and all associated data will be permanently removed.</p>
                                        <li><b>Final Confirmation:</b></li>
                                        <p>After typing your reason for deletion and checking the acknowledgment box, click on the "Yes" button to finalize the account deletion process.</p>
                                        <li><b>Successful Deletion:</b></li>
                                        <p>Once these steps are completed, the system will confirm that the user account has been successfully deleted.</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="main_footer_div">
                    <div className="inner_footer_div1">
                        <p>
                            <a href="/Privacy-Policy">
                                Privacy Policy
                            </a>
                        </p>
                        <hr />
                        <p>
                            <a href="/Terms-and-Condition">
                                Terms and Condition
                            </a>
                        </p>
                        <hr />
                        <p>
                            <a href="/Account-Deactivation">
                                Account Deactivation
                            </a>
                        </p>
                    </div>
                    <div className="inner_footer_div2">
                        <p>© Copyright by PSO</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account_Deactivation
