/*Note: Auth Reducer ***/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userimage: null,
    userinformation: null,
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        USERIMAGE: (state, action) => {
            const { payload } = action;
            console.log('payload:', payload);
            state.userimage = payload
        },
        USERINFORMATION: (state, action) => {
            const { payload } = action;
            console.log('payload:', payload);
            state.userinformation = payload
        },
    }
});

export const {
    USERIMAGE,
    USERINFORMATION,
} = walletSlice.actions;

export default walletSlice.reducer;