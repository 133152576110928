import React, { useState } from 'react';
import './Loader.css';


function Loader() {


    return (
        <>
            <div className='main_loader_div'>
                <span className="loader"></span>
            </div>
        </>
    );
}

export default Loader;
