import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Card,
    IconButton,
    Modal,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { AccountDeleted, LogoutUser } from "../redux/store/action/auth-action/auth-action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";


function Account_Deletion({ open, handleClose }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);

    const [reason, setreason] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    const resHandler = (data) => {
        if (data?.data?.statusCode === 200 || (data?.data?.succeeded === true)) {
            handleClose();
            setIsloading(false)

            setTimeout(() => {
                dispatch(LogoutUser())
            }, 1000);
        }
    };

    const handleYesClick = () => {
        if (!reason?.trim()) {
            toast.error("Please enter a Reason!");
        } else if (!isChecked) {
            toast.error("Select Checkbox!");
        } else {
            setIsloading(true)
            // API CALL TO DELETE ACCOUNT
            let formData = {
                reason: reason,
                signature: "string",
                isUserSure: true
            }

            dispatch(AccountDeleted(formData, resHandler))
        }
    };



    return (
        <>
            {isLoading ? (<Loader />) : (<></>)}
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className="main_account_deletion_div">
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginBottom: "60px",
                        }}
                    >
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box mt={2} mb={5}>

                    </Box>
                    <Typography
                        sx={{
                            color: "#1591c7",
                            fontSize: "20px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Are you sure you want to delete this account?
                    </Typography>
                    <Typography
                        sx={{
                            color: "black",
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Reason for Deletion (recommended)
                    </Typography>
                    <div
                        style={{ width: "100%", display: "flex", justifyContent: "center", padding: "20px" }}
                    >
                        <TextField
                            id="outlined-multiline-static"
                            label="Reason"
                            multiline
                            minRows={4}
                            maxRows={4}
                            style={{ width: "90%" }}
                            value={reason}
                            onChange={(e) => setreason(e.target.value)}
                        />
                    </div>
                    <Typography
                        sx={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        <label>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                            />
                            &nbsp;
                            &nbsp;
                            I understand that deleting my account is irreversible
                            <br />
                            and all my data will be removed
                        </label>
                    </Typography>
                    <Stack direction="row" justifyContent="center" spacing={5} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "green",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                            onClick={handleYesClick}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                            onClick={handleClose}
                        >
                            No
                        </Button>
                    </Stack>
                </div>
            </Modal>
        </>
    );
};


export default Account_Deletion
