// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCqLXf7JmYArm9ARJ3Whl-rQBRiPngiHOw",
    authDomain: "blue-app-dfad4.firebaseapp.com",
    databaseURL: "https://blue-app-dfad4-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "blue-app-dfad4",
    storageBucket: "blue-app-dfad4.appspot.com",
    messagingSenderId: "69137852709",
    appId: "1:69137852709:web:eed696394579e916d412cc",
    measurementId: "G-NHNSFRKXD5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);


export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        console.log('PERMS:', permission);
        if (permission === 'granted') {
            const token = await getToken(messaging, {
                vapidKey: 'BIRp9GnKtW8WYmJXlO5lug7dRlWY8PgbuDKeX6D6832b5qCcK8jt3ZZjPgujC-CRjLwFc-CixSOAd1IomJe7iBU'
            });
            console.log('TOKEN:', token);
            localStorage.setItem("firebasetoken", token);
            // return token; // Add this line to return the token
        }
    } catch (err) {
        console.log('ERR:', err);
    }
};