import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth-reducer/auth-reducer";
import dashboardReducer from "./unauth-reducer/dashboard-reducer/dashboard-reducer";
import walletReducer from "./unauth-reducer/wallet-reducer/wallet-reducer";
import orderReducer from "./unauth-reducer/order-reducer/order-reducer";
import addressReducer from "./unauth-reducer/address-reducer/address-reducer";

const persistConfig = {
    key: 'root',
    storage,
    whiteList: ['authStates']
}

const rootReducer = combineReducers({
    authStates: authReducer,
    dashboardStates: dashboardReducer,
    walletStates: walletReducer,
    orderStates: orderReducer,
    addressStates: addressReducer,
});

export default persistReducer(persistConfig, rootReducer)