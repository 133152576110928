/*Note: Auth Reducer ***/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectaddress: null,
}

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        SELECTADDRES: (state, action) => {
            const { payload } = action;
            console.log('payload:', payload);
            state.selectaddress = payload
        },
    }
});

export const {
    SELECTADDRES,
} = addressSlice.actions;

export default addressSlice.reducer;