// import axios from "axios"
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from "../../../../../libraries-configuration/axios-config/axios-config";
import apiCallMethods from '../../../../../libraries-configuration/api-methods/api-methods';
import { ALLCYLINDERSTATUSLIST } from '../../../reducer/unauth-reducer/dashboard-reducer/dashboard-reducer';

let endPoints = {
    getlistallcylinderstatus: '/CylinderStatus/ListAllCylinderStatusUserWise?customerId=',
};

const GetListAllCylinderStatus = (customerId, resHandler) => {
    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.GET,
                url: endPoints.getlistallcylinderstatus + customerId, // Corrected endpoint key
            });

            console.log('GetListAllCylinderStatus data:', response);
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {
                    dispatch({
                        type: ALLCYLINDERSTATUSLIST,
                        payload: data?.data
                    });
                    resHandler(response);
                    // toast.success(data?.message);
                } else {
                    toast.error(data?.message); // Corrected toast message
                }
            }

        } catch (error) {
            resHandler(error?.response?.data);
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};

export {
    GetListAllCylinderStatus,
};

<ToastContainer />; // Place the ToastContainer outside of functions