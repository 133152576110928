/*Note: Auth Reducer ***/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    walletList: null,
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        WALLETLIST: (state, action) => {
            const { payload } = action;
            console.log('payload:', payload);
            state.walletList = payload
        },
    }
});

export const {
    WALLETLIST,
} = walletSlice.actions;

export default walletSlice.reducer;