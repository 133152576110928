import React, { useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';


const In_house_cylinder = () => {

    const { AllCylinderStatusList } = useSelector(({ dashboardStates }) => dashboardStates);

    const data = useMemo(() => AllCylinderStatusList || [], [AllCylinderStatusList]);

    console.log("datadatadatadata:", data);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'orderId',
                header: 'Order ID',
            },
            {
                accessorKey: 'barcode',
                header: 'Barcode',
            },
            {
                accessorKey: 'at',
                header: 'Delivery Date',
                Cell: ({ row }) => dayjs(row?.original?.at).format('DD-MM-YYYY'),
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data,

    });

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
}
export default In_house_cylinder
