import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  CancelRecurringOrder,
  CancelSubscription,
  CreateRecurringOrder,
  GetCustomerOrder,
  GetCustomerScheduledOrder,
} from "../../../../redux/store/action/unauth-action/order-action/order-action";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FaCalendarAlt } from "react-icons/fa";
import Loader from "../../../../Loader/Loader";
import dayjs from "dayjs";
import checkedcylinder from "../../../../../src/assets/checkedcylinder.png";
import { GetCustomerWallet } from "../../../../redux/store/action/unauth-action/wallet-action/wallet-action";

function Scheduled() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);

  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const { authuserdetail } = useSelector(({ authStates }) => authStates);
  const { walletList } = useSelector(({ walletStates }) => walletStates);
  const {
    paymentmethodslist,
    currentprices,
    createorderdetail,
    itemlist,
    scheduledorderList,
    recuringorderconfirmedlist,
  } = useSelector(({ orderStates }) => orderStates);

  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [RowData, setRowData] = useState(null);
  const [Delivery_Charges, setDelivery_Charges] = useState(null);
  const [Urgent_Delivery_Charges, setUrgent_Delivery_Charges] = useState(0);
  const [isUrgent, setIsUrgent] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const FilterObject = currentprices.find(
    (price) => price.city === selectaddress.city
  );

  //! Delivery Type Logic Start
  const handleDeliveryType = (charges) => {
    if (isUrgent === false) {
      setIsUrgent(true);
      setUrgent_Delivery_Charges(
        FilterObject?.urgentDeliveryCharges * RowData?.qty
      );
      setRowData((prev) => {
        return {
          ...prev,
          amount: prev?.amount + charges * RowData?.qty,
        };
      });
    } else {
      setIsUrgent(false);
      setUrgent_Delivery_Charges(0);
      setRowData((prev) => {
        return {
          ...prev,
          amount: prev?.amount - charges * prev?.qty,
        };
      });
    }
  };
  //! Delivery Type Logic End

  useEffect(() => {
    if (currentprices && RowData) {
      const FilterObject = currentprices.find(
        (price) => price.city === selectaddress.city
      );
      setDelivery_Charges(FilterObject?.deliveryCharges * RowData?.qty);
      // setUrgent_Delivery_Charges(FilterObject?.urgentDeliveryCharges);
    }
  }, [RowData]);

  useEffect(() => {
    const date = new Date();
    const formattedDate = formatDate(date);
    setCurrentDate(formattedDate);
  }, []);

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handleDateChange = (e) => {
    setCurrentDate(e.target.value);
  };

  const handleOpen = (row) => {
    setRowData(row.original);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resHandler = (data) => {
    if (data) {
      setIsloading(false);
      setOpen(false);
    }
  };

  const RecurringresHandler = (data) => {
    console.log("Badchod RowData:::::::::::: ", RowData, data);
    // data.data.data.orderId
    setRowData((prevState) => ({
      ...prevState,
      orderId: data.data.data.orderId,
    }));
    if (data?.data?.statusCode === 200 || data?.data?.succeeded === true) {
      // console.log("i am here");
      dispatch(
        GetCustomerScheduledOrder(
          authuserdetail?.customer?.customerId,
          resHandler
        )
      );
      setOpen(false);
      setConfirmationModal(true);
      handleClose();
      setIsloading(false);
      setIsUrgent(false);
      setDelivery_Charges(0);
    }
  };

  const ConformRecurringOrderFunction = (row) => {
    setConfirmationOpen(true);
    console.log("Conform Recurring Order Function", row.original);
    const RowData = row.original || row;
    setRowData(RowData);
    console.log(RowData);
    // setIsloading(true) RowData?.nextScheduleDate
    // setCurrentDate(RowData?.nextScheduleDate)

    // let formData = {
    //     "schedulerId": RowData?.recurringSchedulerId,
    //     "total": RowData?.total,
    //     "amount": RowData?.amount,
    //     "paymentMethodId": RowData?.paymentMethodId,
    //     "newRequiredDate": currentDate
    // };

    // dispatch(CreateRecurringOrder(formData, RecurringresHandler));
  };

  // useEffect(() => {
  //     if (currentDate) {

  //         const currentDateFormatted = dayjs(currentDate).format('DD-MM-YYYY');
  //         const nextScheduleDateFormatted = dayjs(RowData?.nextScheduleDate).format('DD-MM-YYYY');

  //         if (nextScheduleDateFormatted === currentDateFormatted) {
  //             setCurrentDate(RowData?.nextScheduleDate);
  //             // alert("i am here")
  //         }

  //     }
  // }, [currentDate])

  const CancelRecurringOrderFunction = (row) => {
    // console.log("Cancel Recurring Order Function");
    const RowData = row.original || row;
    setRowData(RowData);
    setcancelRowData(RowData);
    setcancelOpen(true);

    // console.log(RowData);
    // setIsloading(true)
    // let formData = {
    //     "scheduleId": RowData?.recurringSchedulerId,
    //     "customerId": RowData?.customerId,
    //     "isActive": false
    // };

    // dispatch(CancelRecurringOrder(formData, resHandler));
  };

  const data = useMemo(
    () =>
      (scheduledorderList || []).filter(
        (row) => row?.status !== "Deactivated" && row.isScheduleActive == true
      ),
    [scheduledorderList]
  );
  console.log("data: ", data);

  const columns = useMemo(
    () => [
      {
        accessorKey: "orderId",
        header: "Order ID",
      },
      {
        accessorKey: "nextScheduleDate",
        id: "nextScheduleDate",
        header: "Date",
        Cell: ({ row }) => (
          <p>{dayjs(row?.original?.nextScheduleDate).format("MM-DD-YYYY")}</p>
        ),
      },
      {
        accessorKey: "qty",
        header: "Quantity",
      },
      {
        accessorKey: "orderId",
        header: "",
        size: 150,
        Cell: ({ row }) => {
          const nextScheduleDate = new Date(row.original.nextScheduleDate);
          const requiredDate = new Date(row.original.requiredDate);
          const currentDate = new Date();
          const timeDifference = nextScheduleDate - currentDate;
          const daysDifference = Math.floor(
            timeDifference / (1000 * 3600 * 24)
          );

          const showButton =
            (nextScheduleDate < requiredDate ||
              (nextScheduleDate > requiredDate && daysDifference <= 23)) &&
            row.original.status === "Delivered";

          return (
            <div className="main_scheduled_div">
              {showButton ? (
                <>
                  <button
                    style={{ backgroundColor: "#03a07b" }}
                    onClick={() => ConformRecurringOrderFunction(row)}
                  >
                    Confirm
                  </button>
                  <button
                    style={{ backgroundColor: "#195389" }}
                    onClick={() => handleOpen(row)}
                  >
                    Edit
                  </button>
                </>
              ) : (
                <div className="main_detail_button_holder">
                  <div className="main_detail_button_holder">
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#C10100",
                        cursor: "pointer",
                        border: "2px solid #C10100",
                        color: "white",
                        borderRadius: "5px",
                        padding: 10,
                      }}
                      onClick={() => cancelhandleOpen(row)}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [cancelopen, setcancelOpen] = useState(false);
  const [cancelrowData, setcancelRowData] = useState(null);

  const cancelhandleOpen = (row) => {
    console.log("Row Data>>>>>>>>>", row?.original);
    setcancelRowData(row?.original);
    setcancelOpen(true);
  };

  const cancelhandleClose = () => {
    setcancelOpen(false);
  };

  const CancelSubscriptionresHandler = (data) => {
    if (data) {
    }
  };

  const cancelSubscriptionResHandler = (data) => {
    if (data?.data?.statusCode === 200 || data?.data?.succeeded === true) {
      handleClose();
      cancelhandleClose();
      dispatch(
        GetCustomerScheduledOrder(
          authuserdetail?.customer?.customerId,
          CancelSubscriptionresHandler
        )
      );
      dispatch(
        GetCustomerOrder(
          authuserdetail?.customer?.customerId,
          CancelSubscriptionresHandler
        )
      );
      setIsloading(false);
    }
  };

  const cancelhandleYesClick = () => {
    setIsloading(true);
    const formData = {
      scheduleId: cancelrowData?.recurringSchedulerId,
      customerId: authuserdetail?.customer?.customerId,
      isActive: false,
    };
    // let formData = {
    //     "scheduleId": RowData?.recurringSchedulerId,
    //     "customerId": RowData?.customerId,
    //     "isActive": false
    // };

    dispatch(CancelSubscription(formData, cancelSubscriptionResHandler));
  };

  // Modals Function Start Here
  const [Confirmationopen, setConfirmationOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [OrderConfirmationdetails, setOrderConfirmationdetails] =
    useState(null);

  const handleConfirmationOpen = () => {
    const today = new Date();
    const twoDaysLater = new Date(today.setDate(today.getDate() + 2));
    const yyyy = twoDaysLater.getFullYear();
    const mm = String(twoDaysLater.getMonth() + 1).padStart(2, "0");
    const dd = String(twoDaysLater.getDate()).padStart(2, "0");
    const finalDate = `${yyyy}-${mm}-${dd}`;
  };

  const ConfirmationopenhandleClose = () => {
    setIsUrgent(false);
    setUrgent_Delivery_Charges(0);
    setConfirmationOpen(false);
  };

  const Confirmationopenopenhandle = () => {
    // setConfirmationModal(true);
    // handleClose();
    setIsloading(true);

    let paidAmount;
    if (
      //   RowData?.total - RowData?.securityDeposit >
      //   walletList?.wallet?.balance
      FilterObject?.price * RowData?.qty >
      walletList?.wallet?.balance
    ) {
      paidAmount = Math.floor(walletList?.wallet?.balance);
    } else {
      //   paidAmount = RowData?.total - RowData?.securityDeposit;
      paidAmount = Math.floor(
        FilterObject?.price * RowData?.qty + Urgent_Delivery_Charges
      );
    }

    let formData = {
      schedulerId: RowData?.recurringSchedulerId,
      //   total: RowData?.total - RowData?.securityDeposit,
      // total: Math.max(
      //   walletList?.wallet?.balance < FilterObject?.price * RowData?.qty
      //     ? Math.floor(
      //         FilterObject?.price * RowData?.qty +
      //         Urgent_Delivery_Charges
      //       ).toFixed(0)
      //     : 0
      // ),
      total: Math.max(
        Math.floor(
          FilterObject?.price * RowData?.qty + Urgent_Delivery_Charges
        ).toFixed(0)
      ),
      amount: Number(Math.floor(FilterObject?.price * RowData?.qty).toFixed(0)),
      paymentMethodId: RowData?.paymentMethodId,
      newRequiredDate: currentDate,
      customerDetailAddressId: Number(selectaddress?.id),
      paidAmount: Number(paidAmount),
      currentPricingId: FilterObject?.pricingId,
      isUrgent: isUrgent,
    };

    console.log("Badchod formData DATA::::: ", formData);
    // thank u modal state
    // setConfirmationModal(true);

    dispatch(CreateRecurringOrder(formData, RecurringresHandler));
  };

  const [Otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationOpen(false);
    setConfirmationModal(false);
    setIsUrgent(false);
    setUrgent_Delivery_Charges(0);
    // navigate('/')
    dispatch(
      GetCustomerWallet(authuserdetail?.customer?.customerId, resHandler)
    );
  };

  const matchingPaymentMethod = paymentmethodslist.find(
    (method) => method.id === Number(OrderConfirmationdetails?.paymentmethod)
  );

  // Calculate the number of days between the current date and the selected date
  const timeDifference =
    new Date(currentDate) - new Date(RowData?.nextScheduleDate);
  let daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
  daysDifference = daysDifference < 0 ? 0 : daysDifference;

  console.log(
    "Badchod Urgent_Delivery_Charges::::::::: ",
    FilterObject?.urgentDeliveryCharges * RowData?.qty,
    FilterObject?.price * RowData?.qty,
    walletList?.wallet?.balance,
    FilterObject?.price * RowData?.qty +
      Urgent_Delivery_Charges -
      walletList?.wallet?.balance >
      0
      ? FilterObject?.price * RowData?.qty +
          Urgent_Delivery_Charges -
          walletList?.wallet?.balance
      : 0
  );
  return (
    <>
      {isLoading ? <Loader /> : <></>}

      {/* Order Confirmation Modal Start Here */}
      <Modal
        open={Confirmationopen}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="main_modal_div" style={{ maxHeight: "90vh", overflow: "auto" }}>
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "10px",
            }}
          >
            Order Confirmation
          </Typography>
          <IconButton
            aria-label="close"
            onClick={ConfirmationopenhandleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box mt={1} mb={0}>
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Typography sx={{ color: "#818181" }}>Item</Typography>
              <Typography sx={{ color: "#1591c7" }}>
                {itemlist[0]?.name}
              </Typography>
            </Stack>
            <Divider variant="middle" flexItem />
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Typography sx={{ color: "#818181" }}>Quantity</Typography>
              <Typography sx={{ color: "#1591c7" }}>{RowData?.qty}</Typography>
            </Stack>
            <Divider variant="middle" flexItem />
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Typography sx={{ color: "#818181" }}>
                Current Delivery Date
              </Typography>
              <Typography sx={{ color: "#1591c7" }}>
                {dayjs(currentDate).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
            <Divider variant="middle" flexItem />
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Typography sx={{ color: "#818181" }}>
                No.of Days for Refill
              </Typography>
              {/* <Typography sx={{ color: "#1591c7" }}>
                {daysDifference} Days
              </Typography> */}
              <Typography sx={{ color: "#1591c7" }}>
                {RowData?.numOfDay} Days
              </Typography>
            </Stack>
            <Divider variant="middle" flexItem />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
              p={1}
            >
              <Typography
                sx={{
                  color: "#818181",
                  display: "flex",
                  alignItems: isSmallScreen ? "start" : "center",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
              >
                Delivery Charges{" "}
              </Typography>
              {console.log("RowData::::::::::::::::: ", RowData)}
              {/* walletList?.wallet?.balance */}
              <Typography sx={{ color: "#1591c7" }}>
                Rs {Delivery_Charges}
              </Typography>
            </Stack>
            <Divider variant="middle" flexItem />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
              p={1}
            >
              <Typography
                sx={{
                  color: "#818181",
                  display: "flex",
                  alignItems: isSmallScreen ? "start" : "center",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    margin: 0,
                    border: isSmallScreen ? "" : "2px solid gray",
                    // border: "2px solid black",
                    padding: isSmallScreen ? "2px" : "5px 15px",
                    borderRadius: isSmallScreen ? "5px" : "20px",
                    boxShadow: isSmallScreen ? "" : "1px 1px 5px gray",
                    // marginLeft: isSmallScreen ? "" : 10,
                    // marginTop: isSmallScreen ? 5 : "",
                    fontSize: isSmallScreen ? "10px" : "12px",
                    minWidth: "100px",
                  }}
                >
                  <Checkbox
                    name="deliveryType"
                    value="Urgent"
                    onChange={() => handleDeliveryType(Urgent_Delivery_Charges)}
                    inputProps={{ "aria-label": "Urgent Delivery" }}
                    size="small" // This makes the checkbox smaller
                    sx={{ padding: 0, marginRight: 1 }}
                  />
                  {isSmallScreen
                    ? `Urgent Delivery Rs ${FilterObject?.urgentDeliveryCharges}`
                    : `Urgent Delivery Charges Rs ${FilterObject?.urgentDeliveryCharges}`}
                </label>
              </Typography>
              {console.log("RowData::::::::::::::::: ", RowData)}
              {/* walletList?.wallet?.balance */}
              <Typography sx={{ color: "#1591c7" }}>
                Rs{" "}
                {isUrgent
                  ? FilterObject?.urgentDeliveryCharges * RowData?.qty
                  : 0}
              </Typography>
            </Stack>
            <Divider variant="middle" flexItem />
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Typography sx={{ color: "#818181" }}>
                Total Bill Amount
              </Typography>
              {/* walletList?.wallet?.balance */}
              {/* <Typography sx={{ color: "#1591c7" }}>
                Rs{" "}
                {RowData?.total !== null
                  ? (RowData?.total - RowData?.securityDeposit)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography> */}
              <Typography sx={{ color: "#1591c7" }}>
                Rs{" "}
                {FilterObject !== null
                  ? Math.floor(
                      FilterObject?.price * RowData?.qty +
                        Urgent_Delivery_Charges
                    )
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography>
            </Stack>
            {walletList?.wallet?.balance > 0 ? (
              <>
                <Divider variant="middle" flexItem />
                <Stack direction="row" justifyContent="space-between" p={1}>
                  <Typography sx={{ color: "#818181" }}>Wallet</Typography>
                  <Typography sx={{ color: "#1591c7" }}>
                    Rs.{" "}
                    {Math.floor(walletList?.wallet?.balance)
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Stack>
              </>
            ) : null}
            {walletList?.wallet?.balance > 0 ? (
              <>
                <Divider variant="middle" flexItem />
                <Stack direction="row" justifyContent="space-between" p={1}>
                  <Typography sx={{ color: "#818181" }}>
                    Total Bill After Wallet Deduction
                  </Typography>
                  {/* <Typography sx={{ color: "#1591c7" }}>
                    Rs.{" "}
                    {Math.max(
                      RowData?.total -
                        RowData?.securityDeposit -
                        walletList?.wallet?.balance,
                      0
                    )
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography> */}
                  <Typography sx={{ color: "#1591c7" }}>
                    Rs.{" "}
                    {/* {Math.max(RowData?.amount - walletList?.wallet?.balance, 0)
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    {FilterObject !== null
                      ? Math.floor(
                          FilterObject?.price * RowData?.qty +
                            Urgent_Delivery_Charges -
                            walletList?.wallet?.balance >
                            0
                            ? FilterObject?.price * RowData?.qty +
                                Urgent_Delivery_Charges -
                                walletList?.wallet?.balance
                            : 0
                        )
                          ?.toFixed(0)
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "0"}
                  </Typography>
                </Stack>
              </>
            ) : null}
            <Divider variant="middle" flexItem />
            <Stack p={1}>
              <Typography sx={{ color: "#818181", fontSize: "15px" }}>
                Delivery Address
              </Typography>
              <Typography sx={{ color: "#1591c7" }}>
                {selectaddress?.address}
              </Typography>
            </Stack>
            <Stack p={1}>
              <Typography sx={{}}>Payment Mode</Typography>
              <Typography sx={{ color: "#1591c7", fontSize: "15px" }}>
                {RowData?.paymentMethod}
              </Typography>
            </Stack>
          </Box>
          <Typography
            sx={{
              color: "#1591c7",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Do you want to place this order?
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={3} p={0}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              onClick={() => Confirmationopenopenhandle()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={ConfirmationopenhandleClose}
            >
              No
            </Button>
          </Stack>
        </div>
      </Modal>
      {/* Order Confirmation Modal End Here */}

      {/* Thank you for your purchase Modal Start Here */}
      <Modal
        open={confirmationModal}
        aria-labelledby="third-modal-title"
        aria-describedby="third-modal-description"
      >
        <div className="main_modal_div" style={{ maxHeight: "90vh", overflow: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="blue_logo"
              src={checkedcylinder}
              alt="Order Placed"
              style={{ width: "100px", height: "100px" }}
            />
          </Box>
          <Typography
            id="third-modal-title"
            variant="h4"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "23px",
            }}
          >
            Thank you for your purchase
          </Typography>
          <Typography
            id="third-modal-description"
            sx={{ textAlign: "center", fontSize: "17px" }}
          >
            Your order {RowData?.orderId} has been recieved
          </Typography>
          <Typography
            id="third-modal-description"
            sx={{ textAlign: "center", fontSize: "13px" }}
          >
            You will get a confirmation shortly
          </Typography>

          <Stack marginTop={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="#818181">Quantity</Typography>
              <Typography color="#1591c7">{RowData?.qty}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Delivery Date</Typography>
              <Typography sx={{ color: "#1591c7" }}>
                {dayjs(currentDate).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Payment Mode</Typography>
              <Typography color="#1591c7">{RowData?.paymentMethod}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Total Bill Amount</Typography>
              {/* <Typography sx={{ color: "#1591c7" }}>Rs {RowData?.total !== null ? (RowData?.total - RowData?.securityDeposit).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}</Typography> */}
              {/* <Typography sx={{ color: "#1591c7" }}>
                Rs.{" "}
                {Math.max(
                  RowData?.total -
                    RowData?.securityDeposit -
                    walletList?.wallet?.balance,
                  0
                )
                  ?.toFixed(0)
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography> */}
              {/* <Typography sx={{ color: "#1591c7" }}>
                Rs.{" "}
                {FilterObject !== null
                  ? (FilterObject?.price * RowData?.qty +
                      FilterObject?.urgentDeliveryCharges * RowData?.qty -
                      walletList?.wallet?.balance >
                    0
                      ? FilterObject?.price * RowData?.qty +
                        FilterObject?.urgentDeliveryCharges * RowData?.qty -
                        walletList?.wallet?.balance
                      : 0
                    )
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography> */}
              <Typography sx={{ color: "#1591c7" }}>
                Rs.{" "}
                {/* {Math.max(RowData?.amount - walletList?.wallet?.balance, 0)
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                {FilterObject !== null
                  ? Math.floor(
                      FilterObject?.price * RowData?.qty +
                        Urgent_Delivery_Charges -
                        walletList?.wallet?.balance >
                        0
                        ? FilterObject?.price * RowData?.qty +
                            Urgent_Delivery_Charges -
                            walletList?.wallet?.balance
                        : 0
                    )
                      ?.toFixed(0)
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0"}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography color="#818181">Delivery Address</Typography>
              <Stack>
                <Typography color="#1591c7">{selectaddress.address}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography
            sx={{ textAlign: "center", marginTop: "20px", fontSize: "20px" }}
          >
            Kindly show the QR code at the time of delivery
          </Typography>
          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "primary",
                width: "70%",
                height: "50px",
                borderRadius: "20px",
              }}
              onClick={handleConfirmationModalClose}
            >
              Back To Home
            </Button>
          </Box>
        </div>
      </Modal>
      {/* Thank you for your purchase Modal End Here */}

      {/* Rescheduled Modal Start Here */}
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="main_account_deletion_div">
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "60px",
            }}
          ></Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            sx={{
              color: "#1591c7",
              fontSize: { xs: "1.8rem", sm: "1rem" },
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Your order is scheduled to be delivered on{" "}
            {dayjs(currentDate).format("DD-MM-YYYY")}
          </Typography>

          <Stack
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
            p={4}
          >
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#195389",
              }}
              onClick={() => ConformRecurringOrderFunction(RowData)}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#195389",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              component="label"
            >
              Reschedule &nbsp; <FaCalendarAlt />
              <input
                id="reschedule"
                name="reschedule"
                type="date"
                value={currentDate}
                onChange={handleDateChange}
                min={getCurrentDate()}
                style={{
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </Button>
          </Stack>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            p={2}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Don't want to continue with this subscription?
            </Typography>
            <Typography
              sx={{
                color: "red",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => CancelRecurringOrderFunction(RowData)}
            >
              Cancel Subscription
            </Typography>
          </Stack>
        </div>
      </Modal>
      {/* Rescheduled Modal End Here */}

      {/* Cancel Subscription Modal Start Here */}
      <Modal
        open={cancelopen}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 10,
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "60px",
            }}
          ></Typography>
          <IconButton
            aria-label="close"
            onClick={cancelhandleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              color: "#1591c7",
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Do you want to cancel this Subscription?
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={5} p={4}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              onClick={cancelhandleYesClick}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={cancelhandleClose}
            >
              No
            </Button>
          </Stack>
        </Card>
      </Modal>
      {/* Cancel Subscription Modal End Here */}

      <MaterialReactTable table={table} />
    </>
  );
}

export default Scheduled;
